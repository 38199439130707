<template>
  <section :class="['promo', { 'promo--static': isStaticPromo }]">
    <ModalVideoContainer
      v-if="isOpenVideo && backgroundYouTube"
      :open="isOpenVideo"
      :video-url="backgroundYouTube.publicUrl"
      @on-close-modal="closeModalVideo"
    />

    <UiSocials :socials="socialMenu" variant="ui-socials--vertical" />

    <div class="promo__content" :class="textPosition">
      <p class="promo__subtitle">
        {{ subheader }}
      </p>

      <T3HtmlParser
        :tag="`h${headerLayout + 1}`"
        class="ui-header__title promo__title"
        :class="headerClassList"
        :content="header"
      />

      <!-- Button when link is provided and contains anchor to element -->
      <ButtonBase
        v-if="isScrollToButton"
        :button="scrollToButtonProps"
        class="promo__action"
        @click="scrollToHandler"
      />

      <!-- Button when link is provided -->
      <ButtonBase
        v-else-if="button.link || !backgroundYouTube"
        :button="button"
        class="promo__action"
      />

      <!-- Button when link is NOT provided, opening background video -->
      <ButtonBase
        v-else
        :button="button"
        class="promo__action"
        @click="openModalVideo"
      />
    </div>

    <video
      v-if="!isMobileView && !isStaticPromo"
      class="promo__video"
      muted
      autoplay
      loop
    >
      <source
        v-for="video in videosArray"
        :key="video.id"
        :type="video.properties.mimeType"
        :src="video.publicUrl"
      />
      Your browser does not support HTML5 video.
    </video>

    <div v-else class="promo__image-box">
      <UiImg :image="backgroundImage" class="promo__image" />
    </div>
  </section>
</template>

<script>
import {
  ButtonBase,
  ModalVideoContainer,
  UiImg,
  UiSocials
} from '~ui/components'
import useFlatGallery from '~ui/composables/useFlatGallery'
import { getValidLink } from '~ui/helpers/linkUtils'
import { isScrollToLink, scrollToElement } from '~ui/helpers/scrollToElement'

export default {
  components: { ModalVideoContainer, UiSocials, ButtonBase, UiImg },
  props: {
    header: {
      type: String,
      default: ''
    },
    subheader: {
      type: String,
      default: ''
    },
    headerLayout: {
      type: Number,
      default: 1
    },
    headerPosition: {
      type: String,
      default: 'center'
    },
    headerSize: {
      type: Number,
      default: 2
    },
    bodytext: {
      type: String,
      default: ''
    },
    gallery: {
      type: Object,
      default: () => ({})
    },
    socialMenu: {
      type: Array,
      default: () => []
    },
    button: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isOpenVideo: false,
      isMobileView: false
    }
  },
  computed: {
    parsedButtonLink() {
      return getValidLink(this.button.link)
    },
    isScrollToButton() {
      return this.isScrollToLink(this.parsedButtonLink)
    },
    textPosition() {
      return `text-${this.headerPosition}`
    },
    headerClassList() {
      return `heading-${this.headerSize + 1} ${this.textPosition}`
    },
    scrollToButtonProps() {
      return this.isScrollToButton ? { ...this.button, link: '' } : this.button
    },
    backgroundMedia() {
      return useFlatGallery(this.$props).value?.[0]
    },
    backgroundYouTube() {
      return (
        useFlatGallery(this.$props).value.filter(
          (media) => media.properties.mimeType === 'video/youtube'
        )[0] || null
      )
    },
    videosArray() {
      return useFlatGallery(this.$props).value.filter(
        (media) =>
          media.properties.type === 'video' &&
          media.properties.mimeType !== 'video/youtube'
      )
    },
    backgroundImage() {
      return this.isStaticPromo ? this.backgroundMedia : null
    },
    isStaticPromo() {
      return (
        this.backgroundMedia &&
        this.backgroundMedia.properties.type.toLowerCase() !== 'video'
      )
    }
  },
  mounted() {
    this.resizeHandler()
    window.addEventListener('resize', this.resizeHandler)
  },
  unmounted() {
    window.removeEventListener('resize', this.resizeHandler)
  },
  methods: {
    isScrollToLink,
    scrollToElement,
    openModalVideo() {
      this.isOpenVideo = true
    },
    closeModalVideo() {
      this.isOpenVideo = false
    },
    resizeHandler() {
      this.isMobileView = window.innerWidth < 768
    },
    scrollToHandler() {
      if (this.parsedButtonLink && this.isScrollToButton) {
        this.scrollToElement(this.parsedButtonLink)
      }
    }
  }
}
</script>

<style lang="scss">
.promo {
  position: relative;
  display: flex;
  overflow: hidden;
  align-items: center;
  height: calc(100vh - var(--content-offset, rem(0px)));
  height: calc(100svh - var(--content-offset, rem(0px)));
  color: color(white);
  background-color: var(--color__background-color, color(primary));

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    background-color: hsl(0 0% 0% / 33%);
    background: linear-gradient(
      90deg,
      hsl(200 87% 49% / 80%) 20%,
      hsl(200 87% 49% / 75%) 40%,
      hsl(200 87% 49% / 30%) 60%,
      hsl(200 87% 49% / 20%) 65%
    );
  }

  & strong {
    color: color(black);
  }

  &--static {
    background-color: transparent;

    @include media-query(sm) {
      justify-content: space-between;
    }

    @include media-query(lg) {
      & .promo__content {
        padding-right: em(80px);
      }
    }

    &::after {
      background-color: transparent;
    }
  }

  & .ui-socials--vertical {
    position: absolute;
    right: 0;
    z-index: z-index(over);
    top: 50%;
    transform: translateY(-50%);
  }

  &__content {
    padding-inline: spacing(md);

    @include media-query(md) {
      padding-inline: spacing(xl);
    }

    @include media-query(lg) {
      padding-inline: spacing(page);
    }

    padding: var(--content-offset) spacing(xl) spacing(xl) spacing(md);
    z-index: z-index(base);
    width: 100%;
  }

  &__title {
    margin-bottom: spacing(lg);

    @include media-query(lg) {
      max-width: 100%;
    }
  }

  &__subtitle {
    text-transform: uppercase;
    font-weight: 500;
    position: relative;
    display: inline;
    color: color(white);
  }

  &__video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    z-index: z-index(neutral);

    @include media-query(max sm) {
      display: none;
    }
  }

  &__image-box {
    padding-top: em(30px);
    margin-inline: rem(20px) rem(50px);
    width: 40vw;
    min-width: 40vw;

    @include media-query(max sm) {
      display: none;
    }

    @include media-query(md) {
      margin-inline: rem(50px) rem(100px);
      width: 36vw;
      min-width: 36vw;
    }

    @include media-query(lg) {
      margin-inline: rem(80px) rem(150px);
      width: 35vw;
      min-width: 35vw;
    }
  }

  &__image {
    margin: 0 auto;
    width: 100%;
    height: auto;
  }
}
</style>
