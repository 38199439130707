<template>
  <ModalContainer :open="isModalOpen" @on-close-modal="closeModal">
    <div class="embed-container tenc-full-video-container">
      <iframe
        v-if="videoUrl || youtubeId"
        :src="videoUrl || `https://www.youtube.com/embed/${youtubeId}`"
        title="modalVideoContainer"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      />
    </div>
  </ModalContainer>
</template>

<script>
import ModalContainer from './ModalContainer.vue'

export default {
  components: { ModalContainer },
  props: {
    videoUrl: {
      type: String,
      default: ''
    },
    youtubeId: {
      type: String,
      default: ''
    },
    open: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return { isModalOpen: this.open }
  },
  methods: {
    closeModal() {
      this.isModalOpen = false
      this.$emit('on-close-modal')
    }
  }
}
</script>

<style lang="scss">
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  margin: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 100vh;
  }
}
</style>
